<template>
    <div class="wrap p-mypage-leave">
        <layout-header title="탈퇴하기" btn-left="back"></layout-header>
        <div class="container">
            <div class="content">
                <div class="img-wrap">
                    <img alt="" src="/assets/images/mypage/img_mypage_leave.png">
                </div>
                <div class="leave-text">
                    탈퇴를 하시면 회원님의 개인 정보 뿐만 아니라<br>
                    학습 내역 데이터까지 모두 삭제됩니다.<br><br>
                    탈퇴하시겠습니까?
                </div>
                <div class="button-area">
                    <button class="btn-member" type="button" @click="out">탈퇴하기</button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import LayoutHeader from "@/pages/layouts/layoutHeader";

export default {
    name: "confirmOut",
    components: {LayoutHeader},
    data() {
        return {
            coAccountVO: {},
            isParent: false
        }
    },
    created() {
        this.requireLoginMainWithoutAlert()
    },
    mounted() {

        if (this.$route.query.isParent == true) {
            this.coAccountVO = this.$session.get("HWSAccount")
            this.isParent = true
        } else {
            this.getProfile(this.$route.query.caidx)
            this.isParent = false
        }

    },
    methods: {

        getProfile(caidx) {

            const self = this

            this.axiosCaller.get(self, self.APIs.CO_PROFILE + '/getProfileInfo', {
                caidx: caidx
            }, (res) => {

                self.coAccountVO = res.data.profileAccount

            })
        },

        out() {
            const self = this

			delete self.coAccountVO.outDate
			delete self.coAccountVO.createDate
			delete self.coAccountVO.updateDate

            this.swalUtils.fire("탈퇴하시겠습니까?", "탈퇴 후 자동으로 로그아웃되며,\r\n로그인이 불가능합니다.", "error", true)
                .then((result) => {
                    if (result.isConfirmed) {
                        this.axiosCaller.patch(self, this.isParent ? self.APIs.CO_ACCOUNT + '/out' : self.APIs.CO_PROFILE + '/out',
                            self.coAccountVO,
                            (res) => {
                                if (res.data.status === "ok") {
                                    this.swalUtils.gritter("회원탈퇴 완료", "", "success", 3000)
                                        .then(() => {
                                            if (this.isParent) {
                                                this.doLogout()
                                            } else {

                                                self.doLogoutProfile4condition((this.$route.query.caidx * 1 === this.$session.get("HWSSession").caidx * 1), () => {
                                                    self.$router.push({
                                                        name: "profileManage",
                                                    })
                                                })

                                            }
                                        })
                                } else {
                                    this.$swal(res.data.status, result.data.msg, "warning", "확인")
                                    return false
                                }
                            })
                    }
                })
        }
    }
}
</script>

<style scoped>

</style>